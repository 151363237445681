import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const Styles = AsSxObject({
  wrapper: {
    background: "white",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.11)",
    borderRadius: "20px",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  title: {
    fontSize: "20px",
  },
  subtitle: {
    fontSize: "14px",
    color: g.color.gray500,
    textDecoration: "underline",
  },
  dateTime: {
    padding: "4px 8px",
    borderRadius: "4px",
    fontWeight: "medium",
    lineHeight: "24px",
    color: (t) => t.palette.primary.main,
    backgroundColor: g.color.secondary,
  },
  location: {
    display: "flex",
    gap: "8px",
  },
  location__icon: {},
  location__text: {
    color: g.color.gray500,
    fontSize: "14px",
  },
});

export default Styles;
