import { AsSxObject } from "src/utils/AsSxObject";

const Global = AsSxObject({
  container: {
    paddingLeft: "20px",
    paddingRight: "20px",
    "@media(min-width: 1020px)": {
      paddingLeft: "65px",
      paddingRight: "65px",
    },
  },
  color: {
    black: "#000000",
    white: "#ffffff",
    neutral: {
      100: "#F7F7F7",
      200: "#F3F3F3",
      300: "#00000029",
      400: "#00000029",
      500: "#707070",
    },
    primary: "#2946D6",
    secondary: "#E4E6F5",
    gray500: "#667085",
    gray900: "#101828",
  },
  button: {
    secondary: {
      backgroundColor: "#E4E6F5",
      fontFamily: "Metropolis-Regular",
      color: "#2946D6",
      "&:hover": {
        boxShadow: "0 0 10px #e4e6f5",
        backgroundColor: "#2946d6",
        color: "#ffffff",
      },
    },
    hasIcon: {
      paddingBlock: "13px",
      paddingInline: "90px",
      width: "100%",
      maxWidth: "150px",
      whiteSpace: "nowrap",
    },
    circleIcon: {
      backgroundColor: "#E4E6F5",
      fontFamily: "Metropolis-Regular",
      color: "#2946D6",
      padding: "24px",
      width: "10px",
      height: "10px",
      minWidth: "0",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "#2946D6",
        color: "#E4E6F5",
      },
    },
    circleIconTrue: {
      fontFamily: "Metropolis-Regular",
      padding: "24px",
      width: "10px",
      height: "10px",
      minWidth: "0",
      backgroundColor: "#2946D6",
      color: "#E4E6F5",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "#2946D6",
        color: "#E4E6F5",
      },
    },
    circleIconFalse: {
      backgroundColor: "#E4E6F5",
      fontFamily: "Metropolis-Regular",
      color: "#2946D6",
      padding: "24px",
      width: "10px",
      height: "10px",
      minWidth: "0",
      cursor: "not-allowed",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "#E4E6F5",
        color: "#2946D6",
      },
    },
  },
  contentWrapper: {
    marginLeft: "250px",
  },
});

export default Global;
