import { Typography } from "@mui/material";
import Footer from "src/components/frontend/Footer/Footer";
import Navbar from "src/components/frontend/Navbar/Navbar";
import Title from "src/components/frontend/Title/Title";
import TitleStyle from "src/components/frontend/Title/TitleStyle";
import Workshops from "src/components/frontend/Workshops";

const WorkshopOverview = () => {
  return (
    <>
      <Navbar />
      {/* <Title text="Belevingstraject voorjaar 2025" /> */}
      <Typography
        sx={{ ...TitleStyle.style, color: "#0B2193" }}
        variant="h1"
        component="h1"
      >
        Belevingstraject voorjaar 2025
      </Typography>
      <Workshops />
      <Footer />
    </>
  );
};

export default WorkshopOverview;
