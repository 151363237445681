import { ThemeProvider } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import ReactPixel from "react-facebook-pixel/dist/fb-pixel";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { MAIN_DOMAIN } from "./constants/endpoint";
import useAxiosInterceptor from "./hooks/useAxiosInterceptor";
import useRole from "./hooks/useRole";
import NotFound from "./pages/404";
import AppRouter from "./router/AppRouter";
import authRoutes from "./router/authRoutes";
import publicRoutes from "./router/publicRoutes";
import userRoutes from "./router/userRoutes";
import configureStore from "./store/_configureStore";
import "./styles/styles.scss";
import Theme from "./utils/Theme";

const { persistor, store } = configureStore();
axios.defaults.baseURL = MAIN_DOMAIN;
// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

function AppWithStore() {
  useAxiosInterceptor();

  const userRole = useRole();
  useEffect(() => {
    ReactPixel.pageView();
  }, []);
  // useRefreshToken();
  return (
    <Router>
      <AppRouter />
      <Routes>
        {publicRoutes.map((route) => (
          <Route key={route.path} {...route} />
        ))}

        {userRoutes(userRole).map((route) => (
          <Route key={route.path} {...route} />
        ))}

        {authRoutes.map((route) => (
          <Route key={route.path} {...route} />
        ))}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={<div>Loading</div>} persistor={persistor}>
          <Helmet>
            <title>Prijs rijinstructeur lespakketten</title>
            <link rel="icon" href="/ico-logo.ico" />
            <meta
              name="description"
              content="Bekijk de prijs voor een opleiding als rijinstructeur. Krijg toegang tot het nodige lesmateriaal, afhankelijk van het gekozen lespakket."
            />
          </Helmet>
          <ThemeProvider theme={Theme}>
            <AppWithStore />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
