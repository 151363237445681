import { Box, Button, Container, Input, Typography } from "@mui/material";
import c from "./WorkshopsStyle";
import Card from "./Card";
import IcSearch from "src/components/atoms/icons/Svg/IcSearch";
import IcLocation from "src/components/atoms/icons/Svg/IcLocation";
import { useEffect, useState } from "react";
import axios from "axios";
import IcClose from "src/components/atoms/icons/Svg/IcClose";
import ErrorPage from "src/components/moleculs/ErrorPage/ErrorPage";
import { URI } from "src/constants/endpoint";

const Workshops = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const [filter, setFilter] = useState({
    page: 1,
    per_page: 100,
    title: "",
  });

  useEffect(() => {
    getWorkshopList();
  }, [filter]);
  const getWorkshopList = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${URI.workshops.list}`, {
        params: filter,
      });
      setData(data.data);
    } catch (error) {
      console.error("Error fetching workshops:", error);
      setData(null);
    }
    setIsLoading(false);
  };

  // * Filter functionality just incase they need it later
  // const handleInputChange = (e) => {
  //   setInputValue(e.target.value);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setFilter((prev) => ({
  //     ...prev,
  //     title: inputValue,
  //     page: 1,
  //   }));
  // };

  // const handleClearFilter = () => {
  //   setInputValue("");
  //   setFilter({
  //     page: 1,
  //     per_page: 100,
  //     title: "",
  //   });
  // };

  return (
    <Box sx={c.wrapper}>
      {/* Filter section just incase they need it later */}
      {/* <Box component={"section"} id="WorkshopsFilter">
        <Container>
          <Box component={"form"} sx={c.form} onSubmit={handleSubmit}>
            <Box className={"FormField"} sx={c.form__input}>
              <IcSearch />
              <Input
                placeholder="Zoek een functie"
                onChange={handleInputChange}
                value={inputValue}
              />
              {inputValue && (
                <Button sx={c.form__close} onClick={handleClearFilter}>
                  <IcClose />
                </Button>
              )}
            </Box>
            <Button sx={c.form__button} variant="contained" type="submit">
              Zoeken
            </Button>
          </Box>
        </Container>
      </Box> */}

      <Box component={"section"} id="WorkshopsCard">
        <Container>
          <Typography sx={c.title} variant="h1" component={"p"}>
            Het programma
          </Typography>

          <Box sx={c.card__wrapper}>
            {data && data.workshops.length > 0 ? (
              data.workshops.map((item) => {
                const dateTime = `${item.date} van ${item.start_time} - ${item.end_time}`;
                return (
                  <Box key={item.id} sx={c.card__item}>
                    <Card
                      title={item.title}
                      dateTime={dateTime}
                      location={item.location}
                    />
                  </Box>
                );
              })
            ) : (
              <ErrorPage title="Geen workshop gevonden" />
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Workshops;
