import { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { AddSharp, QuestionMarkSharp, RemoveSharp } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";
import WrapperContent from "src/components/moleculs/WrapperContent";
import Swipper from "src/components/moleculs/Swipper";
import Question from "./Utils/Question";
import SlidePopup from "./Utils/SlidePopup";
import g from "src/utils/MuiStyle/Global";
import SwalCatch from "src/utils/helpers/SwalCatch";
import Loading from "src/components/atoms/Loading";
import SlidePerSlide from "./SlidePerSlide";
import c from "./SlidesStyle";

const Slides = ({ firstData }) => {
  const hash = window.location.hash;
  const removedHash = hash.substring(1);

  const [openModal, setOpenModal] = useState(false);
  const [slideIndex, setSlideIndexModal] = useState(0);
  const [slidePopup, setSlidePopup] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [dataSlidePopup, setDataSlidePopup] = useState([]);
  const [isLoadPopup, setIsLoadPopup] = useState(true);
  const [datas, setDatas] = useState([]);
  const param = useParams();

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setIsLoadPopup(true);
    window.history.replaceState("", document.title, window.location.pathname);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (isMounted) {
      Hit(controller);
    }

    return () => {
      controller.abort();
      setSlideIndexModal(0);
      setIsLoad(true);
      isMounted = false;
    };
  }, [param.id]);

  // TODO: push array of LIST_IMAGE
  function flatten() {
    var flat = [];
    for (var i = 0; i < arguments.length; i++) {
      if (arguments[i] instanceof Array) {
        flat.push.apply(flat, flatten.apply(this, arguments[i]));
      } else {
        flat.push(arguments[i]);
      }
    }
    return flat;
  }

  // TODO: Show popup slide
  useEffect(() => {
    const filterSlidePopup = () => {
      // Get data from response API
      const sData = datas;
      const fData = sData.map((s) => s?.slider_img);

      // Filtering content by ID_IMAGE
      const args = flatten(fData);
      const filtered = args
        .filter((g) => g?.id_image === removedHash)
        .map((t) => t?.list_image);
      const filteredArr = filtered.map((s) => s);

      // @ts-ignore
      setDataSlidePopup(...filteredArr);
      setIsLoadPopup(false);
    };

    if (hash) {
      filterSlidePopup();
      setSlidePopup(true);
    }
  }, [hash]);

  const Hit = async (controller) => {
    await axios
      .get(`/api/slides/${param.id ?? firstData?.slug}`, {
        signal: controller.signal,
      })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          const errors = res.data?.errors;
          SwalCatch(errors, true);
        }
        setDatas(res.data.items);
        setIsLoad(false);
      })
      .catch((err) => {
        const errors = err?.response?.data?.errors;
        if (err?.message !== "canceled") {
          SwalCatch(errors, true);
        }
      });
  };

  return (
    <>
      <WrapperContent isLight isSm>
        {isLoad ? (
          <Loading sxs={{ position: "absolute", background: "transparent" }} />
        ) : (
          <Swipper
            title={null}
            handlePopupProps={handleOpen}
            onSlideChange={(prev) => {
              setSlideIndexModal(prev);
            }}
            initialSlide={slideIndex}
            datas={datas?.reverse()}
            content={(item, index) => (
              <>
                <SlidePerSlide item={item} />
              </>
            )}
            isKeyboardArrow={false}
            footer={() => ""}
          />
        )}

        {/* Slide Modal */}
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={c.modal}>
          <Box sx={c.modalWrapper}>
            <Swipper
              title={null}
              handlePopupProps={handleOpen}
              onSlideChange={(prev) => {
                setSlideIndexModal(prev);
              }}
              initialSlide={slideIndex}
              datas={datas?.reverse()}
              content={(item, index) => (
                <>
                  <SlidePerSlide item={item} />
                </>
              )}
              footer={() => ""}
            />
          </Box>
        </Modal>
      </WrapperContent>

      {/* a tag href Modal */}
      {!isLoadPopup ? (
        <Modal
          open={slidePopup}
          onClose={() => {
            setSlidePopup(false);
            setIsLoadPopup(true);
            window.history.replaceState(
              "",
              document.title,
              window.location.pathname,
            );
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={c.modal}>
          <Box sx={c.modalWrapper}>
            <Swipper
              title={null}
              handlePopupProps={() => {
                setSlidePopup(false);
                setIsLoadPopup(true);
                window.history.replaceState(
                  "",
                  document.title,
                  window.location.pathname,
                );
              }}
              iconToggle
              initialSlide={0}
              onSlideChange={() => 0}
              datas={dataSlidePopup}
              content={(item, index) => (
                <>
                  <SlidePopup item={item} />
                </>
              )}
              footer={() => ""}
            />
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

const FooterSlide = ({
  questions,
  addClick,
  removeClick,
  openedIndices,
  childlength = 0,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  let finalQuestion = questions.length > 0 ? questions : false;

  return (
    <>
      <Box sx={c.footerSlide}>
        <Button
          variant="contained"
          sx={
            !finalQuestion ? g.button.circleIconFalse : g.button.circleIconTrue
          }
          onClick={!finalQuestion ? null : handleOpen}>
          <QuestionMarkSharp />
        </Button>
        <Box sx={{ ...c.footerSlidePlusMinus, display: "none" }}>
          <Button
            className="minusss"
            variant="contained"
            sx={
              openedIndices <= 2
                ? g.button.circleIconFalse
                : g.button.circleIconTrue
            }
            onClick={removeClick}>
            <RemoveSharp />
          </Button>
          <Button
            className="plusss"
            variant="contained"
            sx={
              openedIndices > childlength
                ? g.button.circleIconFalse
                : g.button.circleIconTrue
            }
            onClick={openedIndices <= childlength ? addClick : () => false}>
            <AddSharp />
          </Button>
        </Box>
      </Box>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={c.modal}>
        <Box sx={c.modalWrapper}>
          <Swipper
            title={null}
            handlePopupProps={handleClose}
            iconToggle
            initialSlide={0}
            onSlideChange={() => 0}
            allowTouchMove={false}
            datas={finalQuestion}
            content={(item) => <Question item={item} />}
            footer={() => ""}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Slides;
