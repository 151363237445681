import { Box, Button, Typography } from "@mui/material";
import c from "./CardStyle";
import { Link } from "react-router-dom";
import ArrowRight from "src/components/atoms/icons/Svg/ArrowRight";
import IcLocation from "src/components/atoms/icons/Svg/IcLocation";

/**
 * @typedef {object} TCard
 * @property {string} title
 * @property {string} dateTime
 * @property {string} location
 *
 * @param {TCard} param0
 * @returns
 */
const Card = ({ title, dateTime, location }) => {
  return (
    <Box sx={c.wrapper}>
      <Box>
        <Typography sx={c.subtitle} variant="h5" component={"span"}>
          Het programma
        </Typography>
      </Box>
      <Box>
        <Typography sx={c.dateTime} variant="body1" component={"span"}>
          {dateTime}
        </Typography>
      </Box>
      <Box>
        <Typography sx={c.title} variant="h4" component={"span"}>
          {title}
        </Typography>
      </Box>
      <Box sx={c.location}>
        <Box sx={c.location__icon}>
          <IcLocation />
        </Box>
        <Box sx={c.location__text}>{location}</Box>
      </Box>
    </Box>
  );
};

export default Card;
