import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const PrijsStyle = AsSxObject({
  container: { ...g.container, ...{ padding: "0 16px" } },
  wrapperTop: {
    marginTop: "30px",
    "@media(min-width: 600px)": {
      marginTop: "60px",
    },
    "@media(min-width: 1020px)": {
      paddingLeft: "65px",
      paddingRight: "65px",
      marginTop: "112px",
    },
  },
  wrapperBottom: {
    paddingBottom: "160px",
    marginTop: "160px",
    // marginBottom: "-145px",
    "@media(max-width: 1020px)": {
      marginTop: "45px",
    },
    "@media(max-width: 600px)": {
      paddingBottom: "60px",
    },
  },
  wrapperList: {
    maxWidth: "856px",
    marginInline: "auto",
    display: "flex",
    flexDirection: "column",
    rowGap: "82px",
  },
  wrapperWorkshop: {
    padding: "60px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapperContainer: {
    padding: "48px 24px",
    maxWidth: "856px !important",
    marginInline: "auto",
    borderRadius: "16px",
    backgroundColor: (t) => t.palette.primary.dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "32px",
  },
  wrapperWorkshop__Title: {
    fontSize: "24px",
    textAlign: "center",
    color: "#fff",
  },
  wrapperWorkshop__Button: {
    fontSize: "24px",
    textAlign: "center",
    color: "#fff",
  },
});

export default PrijsStyle;
